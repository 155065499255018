import Vue from "vue";
import Router from "vue-router";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.use(Router);

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode: "history",
  routes: [
    {
      path: "/error",
      component: () => import("@/pages/error/index"),
    }, {
        path: "/3d-secure-completed", name: 'Success',
        component: () => import("@/pages/dashboard/component/SecuriyModal")
    }, {
        path: "/progress", name: 'Light FM',
        component: () => import("@/pages/progress-bar/index")
    }, {
        path: "**",
        component: () => import("@/pages/index"),
        children: [
            {
                path: "/:slug", name: 'Dashboard',
                component: () => import("@/pages/dashboard/index")
            }, {
                path: "/update-card/:tokenId", name: 'Dashboard',
                component: () => import("@/pages/dashboard/update-card")
            }, {
                path: "/", name: 'Dashboard',
                component: () => import("@/pages/dashboard/index")
            }
        ]
    }
  ],
});

if (window.VUE_APP_SENTRY_DSN && window.VUE_APP_SENTRY_ORIGINS) {
    Sentry.init({
        Vue,
        dsn: window.VUE_APP_SENTRY_DSN,
        environment: window.VUE_APP_SENTRY_ENV || 'staging',
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: window.VUE_APP_SENTRY_ORIGINS,
            }),
        ],
        tracesSampleRate: 1.0,
    });
}

export default router;
