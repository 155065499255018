import http from '@/http';

export default {
    state: {
        themeModel: {},
        themeLoading: false,
        currentIncentive: null,
        latestIncentiveLoading: false,
        currentCampaign: null
    },
    getters: {
        themeApiModel: state => state.themeModel,
        themeLoading: state => state.themeLoading,
        currentIncentive: state => state.currentIncentive,
        latestIncentiveLoading: state => state.latestIncentiveLoading,
        currentCampaign: state => state.currentCampaign
    },
    mutations: {
        setThemeLoading(state, payload) {
            state.themeLoading = payload;
        },
        setThemeModel(state, payload) {
            state.currentCampaign = payload.active_campaign;
            state.themeModel = payload;
            state.themeLoading = false;
        },
        setLatestIncLoading(state, payload) {
            state.latestIncentiveLoading = payload;
        },
        getCurrentIncentive(state, payload) {
            state.currentIncentive = payload;
            state.latestIncentiveLoading = false;
        },
    },
    actions: {
        getThemeModel ({ commit }, slug) {
            const defaultApiURL = 'themes/active?source=Web Donation Form',
                endPoint = slug ? `${defaultApiURL}&slug=${slug}` : defaultApiURL;
            commit("setThemeLoading", true);
            return new Promise((res, rej) => {
                http('donate').get(endPoint).then(response => {
                    if (response.data.data) {                        
                        commit("setThemeModel", response.data.data);
                    } else commit("setThemeLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setThemeLoading", false);
                    rej(error)
                });
            });
        },
        getLatestIncentive({ commit }, params) {
            let endPoint = 'incentives/active';
            if( typeof params !== 'undefined' && Object.keys(params).length > 0){
                endPoint += '?' + new URLSearchParams(params).toString();
            }
            commit("setLatestIncLoading", true);
            return new Promise((res, rej) => {
                http('donate').get(endPoint).then(response => {
                    commit("getCurrentIncentive", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setLatestIncLoading", false);
                    rej(error)
                });
            });
        }
    },
};
