import http from '@/http';

export default {
    actions: {
        getEmailSuggestion({ commit }, data) {
            const reqData = {email: data};
            return new Promise((res, rej) => {
                http('experian').post('email/validate', reqData).then(response => {
                    res(response.data.data);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
    },
};
