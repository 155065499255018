import http from '@/http';

export default {
    actions: {
        getActiveCampaign({commit}, params){
          let endPoint = 'campaigns/active';
          if( typeof params !== 'undefined' && Object.keys(params).length > 0){
            endPoint += '?' + new URLSearchParams(params).toString();
          }

          return new Promise((res, rej) => {
                http('donate').get(endPoint).then(response => {
                    res(response.data.data);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        getOptionsData({ commit }, optName) {
            return new Promise((res, rej) => {
                http('donate').get(`options/${optName}`).then(response => {
                    res(response.data.data);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
    },
};
