import { APP_VARIABLES } from '@/constants/app-variable';
import * as moment from 'moment';

class globalHelper {

    getConstantsValue(cName) {
        switch (cName) {
        case 'curUser':
            return APP_VARIABLES.currentUser;
        case 'curDonate':
            return APP_VARIABLES.currentDonate;
        default:
            return cName;
        }
    };

    getCurData(dataType) {
        switch (dataType) {
        case 'curUser':
            return localStorage.getItem(APP_VARIABLES.currentUser) ? JSON.parse(localStorage.getItem(APP_VARIABLES.currentUser)) || false : false;
        case 'curToken':
            return this.getCurData('curUser') && this.getCurData('curUser').token ? this.getCurData('curUser').token : false;
        case 'curDonate':
            return localStorage.getItem(APP_VARIABLES.currentDonate) ? localStorage.getItem(APP_VARIABLES.currentDonate) : false;
        default:
            break;
        }
    }

    lsPush(name, value) {
        if (typeof value === 'object') {
        value = JSON.stringify(value);
        }
        localStorage.setItem(this.getConstantsValue(name), value);
    }

    updateLSData(changeDatas, name = "curUser") {
        const prevValue = this.getCurData(name) ? this.getCurData(name) : {},
        updateData = { ...prevValue, ...changeDatas };
        localStorage.setItem(this.getConstantsValue(name), JSON.stringify(updateData));
    }

    setURLparams(dataObj, link) {
        const url = new URL(link);
        Object.keys(dataObj).map(key => url.searchParams.set(key, dataObj[key]));
        return url.toString();
    }

    getMomentDatas (format=null, date=new Date()) {
        if (format) {
        return moment(date).format(format);
        } else {
        return moment(date);
        }
    }

    goDateGvnDay (noOfDay, date=new Date(), type='add', format='YYYY-MM-DD') {
        if (type === 'add') {
        return moment(date, format).add(noOfDay, 'days');
        } else {
        return moment(date, format).subtract(noOfDay, 'days');
        }
    }

    getPaymentFrequency(type) {
        switch (type) {
        case "O":
            return 'Once';
        case "M":
            return 'Monthly'
        case "A":
            return 'All'
        default:
            return type;
        }
    }

    errorMsg(name, model, fieldName) {
        const errors = [];
        if (!model.$dirty) return errors;
        switch (name) {
        case "name":
            !model.required && errors.push(`${fieldName} is required`);
            break;
        case "email":
            !model.email && errors.push("Enter a valid email address");
            model.required !== undefined && !model.required && errors.push(`${fieldName} is required`);
            break;
        case "sameas":
            !model.sameAsfield && errors.push(`${fieldName} does not match`);
            !model.required && errors.push(`${fieldName} is required`);
            break;
        default:
            break;
        }
        return errors;
    }

    getValueOnlyObj(objData) {
        const outputObject = {};
        Object.keys(objData).forEach(obj => {
        if(objData[obj]) outputObject[obj] = objData[obj];
        });
        return outputObject;
    }

    updatePropertyObj(originalObj, updateObj) {
        const outputObject = {};
        Object.keys(originalObj).forEach(obj => outputObject[obj] = (updateObj.hasOwnProperty(obj) ? updateObj[obj] : originalObj[obj]));
        return outputObject;
    }

    resetObjValue(originalObj, defaultObj) {
        const outputObject = {};
        Object.keys(originalObj).forEach(obj => outputObject[obj] = (defaultObj.hasOwnProperty(obj) ? defaultObj[obj] : ''));
        return outputObject;
    }

    focusErrorElement(element, refs) {
        for (let key in Object.keys(element)) {
        const input = Object.keys(element)[key];
        if (input.includes("$")) return false;

        if (element[input].$error && refs && refs[input]) {
            refs[input].focus();
            break;
        }
        }
    }

    titleCase(value){
        return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
    }

    currencyFormat(value, cents) {
        if(value == '-') {
            return value;
        }

        if(!value || typeof value == 'undefined') {
            return 0;
        }

        if(cents === false) {
            value = Math.trunc(value)
        }

        return Intl.NumberFormat('en-AU').format(value);
    }

    avoidInputScrollable(evt) {
        evt.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })
    }

    avoidInputArrowKeyPress(e) {
        if (e.which === 38 || e.which === 40) {
            e.preventDefault();
        }
    }

    removeLSStorage(name) {
        localStorage.removeItem(this.getConstantsValue(name));
    }

    logout() {
        localStorage.removeItem(APP_VARIABLES.currentUser);
        localStorage.removeItem(APP_VARIABLES.currentDonate);
    }
}

export default new globalHelper();