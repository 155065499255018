import http from '@/http';

export default {
    actions: {
        getUpdateCardData({ commit }, token) {
            const reqData = {card_update_token: token};
            return new Promise((res, rej) => {
                http('salesforce').post('opportunities/search', reqData).then(response => {
                    const resp = response.data.data.length && response.data.data[0] ? 
                        response.data.data[0].sf_data : null;
                    if (resp) {
                        resp['donation_id'] = response.data.data[0].donation_id;
                        resp['email'] = response.data.data[0].contact ? response.data.data[0].contact.email || 'anonymous@example.com' : 'anonymous@example.com';
                    };
                    commit("setUpdateCardModel", resp);
                    res(resp);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        updateCardDetails({ commit }, cardData) {
            const reqData = cardData.reqData;
            return new Promise((res, rej) => {
                http('donate').post(`/donates/${cardData.donation_id}/update-card`, reqData).then(response => {
                    const resp = response.data.data || null;
                    commit("stopAppLoading");
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Save Card Successfully",
                        color: "success",
                    });
                    res(resp);
                }).catch((error) => {
                    commit("stopAppLoading");
                    rej(error)
                });
            });
        },
    },
};